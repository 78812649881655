import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../slices/authSlice';
import { resetUserState } from '../slices/userSlice'; // Import resetUserState to clear user data
import { resetAdminTargetUserState } from '../slices/adminTargetUserSlice';


import { auth } from '../firebaseConfig';
import { persistor } from '../store';
import { Link, useNavigate } from 'react-router-dom';

import './Header.css';


// Helper function to format phone numbers
const formatPhoneNumber = (phone) => {
  if (!phone || phone.length < 12) return 'N/A';
  return `(${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8)}`;
};



function Header() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Check if user is logged in from auth slice
  const { firstName, lastName, role } = useSelector((state) => state.user); // Select user info from user slice

  const adminTargetUser = useSelector((state) => state.adminTargetUser); // Get admin target user details



  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        dispatch(clearUser()); // Clear auth state
        dispatch(resetUserState()); // Clear user data
        dispatch(resetAdminTargetUserState()); // Clear admin target user
        persistor.purge(); // Clears all persisted data
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };


  const navigateToServiceHome = () => {
    navigate('/servicehome');
  };



  return (
    <>
      <div className="header-full-width-container">
        <header className="content-container header">

          <div className="header-left">
            <Link to="/">
              <img src="images/logo-dark.png" alt="Sparkle" className="header-logo" />
            </Link>
            {isLoggedIn && role === 'owner' && (
              <Link to="/adminHome">
                <img src="images/admin.png" alt="Admin" className="header-logo" />
              </Link>
            )}
          </div>

          {isLoggedIn && (
            <div className="header-user-status">
              <span className="logged-in-user">{`${firstName} ${lastName}`}</span> 
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </header>
      </div>

      {/* Phone Banner Below Header */}
      <div className="content-container column">

        <div className="top-section-contact-banner">
          <div className="contact-call">
            <span>Give us a call! <a href="tel:8645205018">864-520-5018</a></span>
          </div>
          <div className="contact-hours">
            <span>Everyday, 7AM - 7PM</span>
          </div>
        </div>



        {/* Admin Target User Banner */}
        {isLoggedIn && role === 'owner' && adminTargetUser.id && (
          <div className="admin-target-user-banner">
            <div className="admin-user-info">
              <span>
                Admin Target User -- {`${adminTargetUser.firstName || 'N/A'} ${adminTargetUser.lastName || 'N/A'}`} / 
                <a href={`tel:${adminTargetUser.phone}`}>{formatPhoneNumber(adminTargetUser.phone)}</a>
              </span>
              <button 
                className="customer-home-button"
                onClick={navigateToServiceHome}
              >
                Customer Home
              </button>
            </div>
            <button 
              className="clear-admin-user-button"
              onClick={() => dispatch(resetAdminTargetUserState())}
            >
              Clear Admin User
            </button>
          </div>
        )}




      </div>

    </>
  );
}

export default Header;




