import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../slices/authSlice';

import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';

import { signInWithEmailAndPassword } from 'firebase/auth';  // Firebase function for email sign-in
import { auth } from '../firebaseConfig';  // Firebase auth instance
import { Helmet } from 'react-helmet';


import Checkbox from '../components/Checkbox';
import ErrorBox from '../components/ErrorBox';
import PriceEstimateDisplay from '../components/PriceEstimateDisplay';
import CheckListComponent from '../components/CheckListComponent';
import SectionHeader from '../components/SectionHeader';
import NumberIncrementor from '../components/NumberIncrementor';
import Header from '../components/Header';
import PhoneLogin from '../components/PhoneLogin';
import CardComponent from '../components/CardComponent';


import PhoneInputComponent from '../components/PhoneInputComponent';
import { useNavigate } from 'react-router-dom';





function HomeScreen() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  
  const [squareFeet, setSquareFeet] = useState('');
  const [cleaningType, setCleaningType] = useState('');
  const [priceDetails, setPriceDetails] = useState({ priceTitle: "Estimated Price", price: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [priceError, setPriceError] = useState([]);  
  const [checklistItems, setChecklistItems] = useState([]); 



  const [phone, setPhone] = React.useState("");



  // States for interest form
  const [interestPhone, setInterestPhone] = useState("");
  const [interestFirstName, setInterestFirstName] = useState("");
  const [interestLastName, setInterestLastName] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [interestFormErrors, setInterestFormErrors] = useState([]); // Tracks validation errors
  const [interestMessage, setInterestMessage] = useState("");






  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      dispatch(setUser()); // Just set isLoggedIn to true
      navigate('/servicehome'); // Navigate to /servicehome on successful login
    } catch (err) {
      setError('Failed to sign in. Please check your email and password.');
      console.error('Error during sign-in:', err);
    }
  };

  

  // Helper function to handle phone login success
  const handlePhoneLoginSuccess = () => {
    console.log("Phone login successful. Awaiting user data fetch in App.js...");
  };
  


  const [numberBathrooms, setNumberBathrooms] = useState(1);

  const handleIncrementBathrooms = () => setNumberBathrooms(current => current + 1);
  const handleDecrementBathrooms = () => setNumberBathrooms(current => Math.max(1, current - 1));


  useEffect(() => {
    const fetchChecklistItems = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get-checklist-items`);
        if (response.data && Array.isArray(response.data.checkListItems)) {
          setChecklistItems(response.data.checkListItems); 
        } else {
          setChecklistItems([]); // Ensure it's always an array even if data is missing
          console.error("Expected an array for checklist items, received:", response.data);
        }
      } catch (error) {
        console.error('Failed to fetch checklist items:', error);
        // if want to use, need a dedicated useState
        // setPriceError(prevErrors => [...prevErrors, 'Failed to fetch checklist items']);
      }
    };
  
    fetchChecklistItems();
  }, []);  


  const handleSquareFeetChange = (event) => {
    const value = event.target.value;
    // Allow only numbers that do not start with zero
    if (/^[1-9]\d*$/.test(value) || value === '') {
      setSquareFeet(value);
    }
  };  


  const getGeneralChecklist = (serviceType) => {
    // Assuming allChecklistItems is now a flat array containing all items
    const roomItems = checklistItems || [];
  
    return roomItems
      .filter(item => item.roomType === 'general' && item[`${serviceType}Service`] === true)
      .sort((a, b) => a.generalOrder - b.generalOrder);
  };


  const getRoomTypeChecklist = (roomType, serviceType) => {
    const roomItems = checklistItems || [];
  
    return roomItems
      .filter(item => item.roomType === roomType && item[`${serviceType}Service`] === true)
      .sort((a, b) => a[`${roomType}Order`] - b[`${roomType}Order`]);
  };  


  const handleCleaningTypeChange = (type) => {
    setCleaningType(type);  // Update the state
    if (parseInt(squareFeet, 10) >= 500) {
      fetchPriceEstimate(type);  // Fetch new estimate if conditions are met
    } else {
      // Reset the states only if the square footage is below the threshold
      setSquareFeet('');
      setPriceDetails({ priceTitle: "Estimated Price", price: 0 });
      setPriceError([]);
    }
  };


  const validateInputs = (squareFeet, cleaningType) => {
    const errors = [];
    if (!squareFeet || parseInt(squareFeet, 10) < 500) {
      errors.push("Please enter a square footage estimate of at least 500.");
    }
    if (!cleaningType) {
      errors.push("Please select a cleaning type.");
    }
    return errors;
  };
    
  
  // Helper function to round the price to the nearest 5 dollars
  const roundToNearestFive = (num) => {
    return Math.round(num / 5) * 5;
  };


  // Frontend fetchPriceEstimate function
  const fetchPriceEstimate = async (type) => {
    const currentCleaningType = type || cleaningType; // Use provided type or fall back to the state

    const errors = validateInputs(squareFeet, currentCleaningType);
    if (errors.length > 0) {
      setPriceError(errors);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setPriceError([]);
    try {
      const response = await axios.get(`${apiBaseUrl}/quick-price-estimate`, {
        params: { squareFeet, cleaningType: currentCleaningType, numberBathrooms } // Changed here
      });
      if (response.data && response.status === 200) {
        const roundedPrice = roundToNearestFive(parseFloat(response.data.regularEstimate)); // Ensure conversion to float
        setPriceDetails({
          priceTitle: "Estimated Price With Tax",
          price: roundedPrice
        });
      } else {
        throw new Error('Failed to fetch estimate');
      }
    } catch (err) {
      console.error('API Error:', err);
      setPriceError(['Failed to fetch estimate. Please try again.']);
    }
    setIsLoading(false);
  };

  

  const standardBedroomItems = getRoomTypeChecklist('bedroom', "standard");
  const deepBedroomItems = getRoomTypeChecklist('bedroom', "deep");



  // Form validation function
  const validateForm = () => {
    const newErrors = [];
    if (!interestFirstName.trim()) {
      newErrors.push("Please enter your first name.");
    }
    if (!interestLastName.trim()) {
      newErrors.push("Please enter your last name.");
    }
    // Validate phone number (check if it's complete)
    if (!/^(\(\d{3}\) - \d{3} - \d{4})$/.test(interestPhone)) {
      newErrors.push("Please enter a valid phone number.");
    }
    setInterestFormErrors(newErrors);
    return newErrors.length === 0;
  };



  // Submission function
  const handleInterestSubmit = async () => {
    if (!validateForm()) return; // Stop submission if validation fails
  
    setIsSubmitting(true);
    setInterestFormErrors([]); // Clear any previous errors
  
    try {
      // Format the phone number to +1XXXXXXXXXX
      const formattedPhoneNumber = `+1${interestPhone.replace(/\D/g, "")}`;
  
      // Create the request payload
      const payload = {
        firstName: interestFirstName.trim(),
        lastName: interestLastName.trim(),
        phone: formattedPhoneNumber,
        message: interestMessage.trim(),
      };
  
      console.log("Submitting Interest Form Payload:", payload);
  
      // Send the payload to the backend API
      const response = await axios.post(`${apiBaseUrl}/marketing/submit-interest-form`, payload);
  
      if (response.data && response.data.success) {
        setSubmissionStatus("Thank you! We'll reach out to you soon.");
        // Reset the form
        setInterestPhone("");
        setInterestFirstName("");
        setInterestLastName("");
        setInterestMessage("");
      } else {
        throw new Error("Submission failed. Unexpected response from server.");
      }
    } catch (error) {
      console.error("Failed to submit the interest form:", error);
      setInterestFormErrors(["Submission failed. Please try again."]);
    } finally {
      setIsSubmitting(false);
    }
  };



  return (

    <>

      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LM1DHF2CWD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LM1DHF2CWD');
          `}
        </script>
      </Helmet>;
      
      <div className="full-width-container">

        <Header />

        <div className="content-container column">
          <div className="top-section-column-layout">
            <span className="top-section-feature-title">Welcome to Sparkle!</span>

            <span className="top-section-sub-header">professional house cleaning</span>


            <div className="top-section-welcome-message">
              <p>
                Sparkle is a proud startup based in Greenville, South Carolina! We’re excited to bring professional and reliable house cleaning services to our community. Thank you for supporting a local business—let’s make your home sparkle and shine!
              </p>
            </div>






            
            {/* Interest Section Container */}
            <div className="interest-section-grid">
              {/* Image with Navigation Icons */}
              <div className="interest-section-image">
                <img src="images/backgrounds/clean-kitchen.jpg" alt="Clean Kitchen" className="interest-main-image" />
                <div className="interest-card-container">
                  <CardComponent
                    icon="images/icons/dummy-icon.png"
                    text="Quick Price Estimate"
                    link="#price-estimate"
                  />
                  <CardComponent
                    icon="images/icons/checklist-icon.png"
                    text="Cleaning Checklists"
                    link="#checklists"
                  />
                  <CardComponent
                    icon="images/icons/logo-only.png"
                    text="Service Highlights"
                    link="#sparkle-postcard"
                  />
                  <CardComponent
                    icon="images/icons/app-download.png"
                    text="App Download / Sign-in"
                    link="#app-section"
                  />
                </div>
              </div>

              {/* Interest Form Section */}
              <div className="interest-section-form">
                <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>Want us to reach out to you?</p>
                <p>Fill out this interest form, and we'll get in touch soon:</p>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleInterestSubmit();
                  }}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="interest-first-name">First Name:</label>
                    <input
                      type="text"
                      id="interest-first-name"
                      value={interestFirstName}
                      onChange={(e) => setInterestFirstName(e.target.value)}
                      placeholder="Enter your first name"
                      className="form-input"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="interest-last-name">Last Name:</label>
                    <input
                      type="text"
                      id="interest-last-name"
                      value={interestLastName}
                      onChange={(e) => setInterestLastName(e.target.value)}
                      placeholder="Enter your last name"
                      className="form-input"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="interest-phone-number">Phone Number:</label>
                    <PhoneInputComponent phone={interestPhone} setPhone={setInterestPhone} />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="interest-message">Message (Optional):</label>
                    <textarea
                      id="interest-message"
                      value={interestMessage}
                      onChange={(e) => setInterestMessage(e.target.value)}
                      placeholder="Enter a message or note"
                      className="form-textarea"
                      rows="4"
                    />
                  </div>
                  <button
                    type="submit"
                    className="blue-button marginBottom10"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </form>

                {/* Error messages */}
                <ErrorBox errorTexts={interestFormErrors} />

                {/* Display submission status */}
                {submissionStatus && <p className="submission-status">{submissionStatus}</p>}
              </div>
            </div>
            

          </div>
        </div>



        <div className="content-container column">
          {/* Section Header */}
          <div className="before-after-header">App Feature: Task Management</div>

          <div className="before-after-image-app-container">
            {/* Instructions Section */}
            <div className="before-after-instructions">
              <div className="before-after-step">
                <h3>1. Snap It!</h3>
                <p>Take a quick photo of the task you need cleaned, like a messy countertop or a dusty shelf, and add a short description.</p>
              </div>
              <div className="before-after-step">
                <h3>2. Add More, As Many As You Like...</h3>
                <p>Need more cleaning tasks? Keep adding photos and descriptions to create a to-do list for your cleaner. There's no limit—stack up those chores!</p>
              </div>
              <div className="before-after-step">
                <h3>3. The Cleaner Delivers</h3>
                <p>Your cleaner will complete each task and upload matching after photos to ensure the job is done perfectly. Spotless results guaranteed!</p>
              </div>
            </div>

            {/* Image Section */}
            <div className="before-after-image-container">
              <img
                src="/images/app-screens/before-after.png"
                alt="Before and After Tasks"
                className="before-after-image"
              />
            </div>
          </div>
        </div>





        <div className="content-container column alignCenter" >
          <div className="top-section-app-links" id="app-section">
            <a href="https://apps.apple.com/us/app/sparkle-cleaning/id6474100183" target="_blank" rel="noopener noreferrer">
              <img src="images/appstore.png" alt="Download on the App Store" className="app-store-link" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.cosmos.sparkleapp" target="_blank" rel="noopener noreferrer">
              <img src="images/android-store2.png" alt="Download on Google Play" className="app-store-link" />
            </a>
          </div>
          
          {/* Conditionally render login section if user is not logged in */}
          {!isLoggedIn && (
            <div className="web-app-login-container">
              <div className="web-app-login-header">
                <span>Web App Login</span>
              </div>
              <div className="web-app-login-body">
                <div className="top-section-phone-input-container">
                  <PhoneLogin onVerificationSuccess={handlePhoneLoginSuccess} />

                  {/* New label for email login */}
                  {/* <label htmlFor="email-login" className="phone-input-label">
                    <strong>Or login with your email and password:</strong>
                  </label> */}

                  {/* Inline login form for email sign-in */}
                  {/* <form onSubmit={handleLogin} className="inline-login-form">
                    <div className="input-row">
                      <label>Email:</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="example@example.com"
                        required
                        className="inline-login-input"
                      />
                      <label>Password:</label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Your Password"
                        required
                        className="inline-login-input"
                      />
                      <button type="submit" className="inline-login-button">Sign In</button>
                    </div>
                  </form> */}

                  {/* Display error if it exists */}
                  {error && <ErrorBox errorTexts={[error]} />}
                </div>
              </div>
            </div>
          )}

          {/* Conditionally render Go to Service Home button if the user is logged in */}
          {isLoggedIn && (
            <div className="web-app-login-container">
              <div className="web-app-login-header">
                <span>Web App Login</span>
              </div>
              <div className="web-app-login-body">
                <button className="blue-button margin10" onClick={() => navigate('/servicehome')}>
                  Go to Service Home
                </button>
              </div>
            </div>
          )}
        </div>



        <section className="postcard-section" id="sparkle-postcard">
          <div className="content-container">
            <div className="postcard-grid">
              <div className="postcard-column">
                <h2 className="postcard-title">Our Cleaners</h2>
                <ul className="postcard-list">
                  <li>
                    <strong>Safe:</strong>
                    <span>Background-checked, Bonded, Insured</span>
                  </li>
                  <li>
                    <strong>Good Attitude:</strong>
                    <span>Customer service focus, Friendly, Professional</span>
                  </li>
                  <li>
                    <strong>Motivated:</strong>
                    <span>Competitive pay, Flexible schedules, Growth opportunities</span>
                  </li>
                  <li>
                    <strong>Setup For Success:</strong>
                    <span>Top quality equipment, Trained for efficiency, Loss prevention focus</span>
                  </li>
                </ul>
              </div>
              <div className="postcard-center-container">
                <img src="images/phone-logo.png" alt="Sparkle Logo" className="postcard-logo" />
              </div>
              <div className="postcard-column">
                <h2 className="postcard-title">App Features</h2>
                <ul className="postcard-list">
                  <li>
                    <strong>Custom To-Do Lists:</strong>
                    <span>Cleaner must match an after photo; Tied to property for easy re-use</span>
                  </li>
                  <li>
                    <strong>Accurate Billing:</strong>
                    <span>GPS-verified cleaner logins</span>
                  </li>
                  <li>
                    <strong>Easy Scheduling:</strong>
                    <span>Live calendar, Re-use saved properties, Instant price estimates</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>


        <section className="estimate-container" id="price-estimate">
          <div className="estimate-section">
            <h2>Quick Price Estimate</h2>
            <div>
              <Checkbox
                label="Standard Clean"
                checked={cleaningType === 'standard'}
                onChange={() => handleCleaningTypeChange('standard')}
              />
              <Checkbox
                label="Deep Clean"
                checked={cleaningType === 'deep'}
                onChange={() => handleCleaningTypeChange('deep')}
              />
            </div>

            <input
              type="number"
              placeholder="Square Footage Estimate"
              value={squareFeet}
              onChange={handleSquareFeetChange}
              min="500"
              inputMode="numeric"
              pattern="[0-9]*"
              className="square-footage-input"
            />

            <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>Number of Bathrooms:</h3>

            <NumberIncrementor
              value={numberBathrooms}
              onIncrement={handleIncrementBathrooms}
              onDecrement={handleDecrementBathrooms}
            />

            <button 
              className="get-estimate-button marginTop10"
              onClick={() => fetchPriceEstimate(cleaningType)} // Pass current cleaningType explicitly
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Get Estimate'}
            </button>
            <PriceEstimateDisplay priceDetails={priceDetails} />
            <ErrorBox errorTexts={priceError} />          
          </div>
        </section>


        <section className="content-container checklist-grid-container" id="checklists">
          <div className={`checklist-column ${cleaningType === "standard" ? "selected-type" : ""}`}>
            <SectionHeader
              text="Standard Clean Checklist"
              backgroundColor="#000000"
              fontColor="#FFFFFF"
              textAlign="center"
              fontSize="large"
            />
            <div className="checklist-section">
              <h2>All Rooms - Standard</h2>
              <CheckListComponent
                items={getGeneralChecklist("standard")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            <div className="checklist-section">
              <h2>Bathrooms - Standard</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('bathroom', "standard")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            
            {standardBedroomItems.length > 0 && (
              <div className="checklist-section">
                <h2>Bedrooms - Standard</h2>
                <CheckListComponent
                  items={standardBedroomItems}
                  marginLeft={15}
                  marginBottom={15}
                />
              </div>
            )}
            <div className="checklist-section">
              <h2>Kitchen - Standard</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('kitchen', "standard")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          </div>

          <div className={`checklist-column ${cleaningType === "deep" ? "selected-type" : ""}`}>
          <SectionHeader
              text="Deep Clean Checklist"
              backgroundColor="#000000"
              fontColor="#FFFFFF"
              textAlign="center"
              fontSize="large"
            />
            <div className="checklist-section">
              <h2>All Rooms - Deep</h2>
              <CheckListComponent
                items={getGeneralChecklist("deep")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            <div className="checklist-section">
              <h2>Bathrooms - Deep</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('bathroom', "deep")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            {deepBedroomItems.length > 0 && (
              <div className="checklist-section">
                <h2>Bedrooms - Deep</h2>
                <CheckListComponent
                  items={deepBedroomItems}
                  marginLeft={15}
                  marginBottom={15}
                />
              </div>
            )}
            <div className="checklist-section">
              <h2>Kitchen - Deep</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('kitchen', "deep")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          </div>
        </section>


        <section className="content-container contact-section">


          {/* <div className="social-links">
            <a href="https://www.facebook.com/SparkleCleaningSC/" target="_blank" rel="noopener noreferrer">
              <img src="images/facebook.png" alt="Facebook" />
            </a>
          </div> */}

          <div className="mission-statement-container">
            <div className="mission-statement-header">
              <span className="top-section-mission-title">Mission Statement</span>
            </div>
            <div className="mission-statement-body">
              <span className="top-section-mission">
                To build a workplace that attracts and retains great cleaners, addressing the industry-wide challenges of high turnover and inconsistent service, while leveraging advanced technology to deliver a seamless experience for both cleaners and customers.
              </span>
            </div>
          </div>  

          <div className="top-section-location-container">
              <img 
                src="images/icons/location-icon.png" 
                alt="Location Icon" 
                className="location-icon" 
              />
              <span className="location-text">Greenville, South Carolina</span>
          </div>


          <div className="contact-details">
            <div className="contact-items-wrapper">  
              <div className="contact-item">
                {/* <img src="images/icon-message.png" alt="Email" /> */}
                <div className="link-container">
                  <a href="mailto:contact@sparkleservicesusa.com">contact@sparkleservicesusa.com</a>
                </div>
              </div>
              {/* <div className="contact-item">
                <img src="images/icon-phone.png" alt="Phone" />
                <div className="link-container">
                  <a href="tel:(864) 520-5018">(864) 520-5018</a>
                </div>
              </div> */}
            </div>
          </div>




          <div className="social-links">
            <a href="https://www.facebook.com/SparkleCleaningSC/" target="_blank" rel="noopener noreferrer">
              <img src="images/facebook.png" alt="Facebook" className="facebook-logo" />
            </a>

            <a href="https://www.yelp.com/biz/sparkle-services-usa-greenville" target="_blank" rel="noopener noreferrer">
              <img src="images/yelp.png" alt="Yelp" className="yelp-logo" />
            </a>
          </div>



          <div className="footer-text">
            <p>Copyright 2024 Sparkle Services USA LLC</p>
          </div>
        </section>


      </div>
    



    
    
    
    
    </>

  );


}

export default HomeScreen;
