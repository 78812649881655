import React from "react";
import "./CardComponent.css"; // CSS for the component

const CardComponent = ({ icon, text, link }) => {
  return (
    <a href={link} className="card-component">
      <div className="card-icon">
        <img src={icon} alt="Icon" />
      </div>
      <div className="card-text">
        <span>{text}</span>
      </div>
    </a>
  );
};

export default CardComponent;
